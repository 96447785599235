var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Spacer',[_c('div',{staticClass:"block__label"},[(_vm.data.label && _vm.data.label !== '')?_c('Label',{attrs:{"data":{
        value: _vm.data.label,
        tag: _vm.data.tag_label,
        size: _vm.data.size_label
      }}}):_vm._e()],1),_c('Grid',{attrs:{"col":{
      default : 12,
      'm': 6
    }}},[_c('div',{staticClass:"block__header"},[(_vm.data.title !== '')?_c('Title',{staticClass:"col-12",attrs:{"data":{
          value: _vm.data.title,
          tag: _vm.data.tag_title,
          size: _vm.data.size_title
        }}}):_vm._e(),(!_vm.data.rich_text || _vm.data.rich_text == '')?_c('div',[(_vm.data.cta && _vm.data.cta !== '')?_c('Cta',{attrs:{"data":_vm.data.cta}}):_vm._e()],1):_vm._e()],1),(_vm.data.rich_text !== '')?_c('div',{staticClass:"block__content"},[(_vm.data.rich_text !== '')?_c('RichText',{attrs:{"data":{ value: _vm.data.rich_text }}}):_vm._e(),(_vm.data.cta && _vm.data.cta !== '')?_c('Cta',{attrs:{"data":_vm.data.cta}}):_vm._e()],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }