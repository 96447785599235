<template>
  <Spacer>
    <div class="block__label">
      <Label
        v-if="data.label && data.label !== ''"
        :data="{
          value: data.label,
          tag: data.tag_label,
          size: data.size_label
        }"
      />
    </div>
    <Grid
      :col="{
        default : 12,
        'm': 6
      }"
    >
      <div class="block__header">
        <Title
          v-if="data.title !== ''"
          class="col-12"
          :data="{
            value: data.title,
            tag: data.tag_title,
            size: data.size_title
          }"
        />
        <div v-if="!data.rich_text || data.rich_text == ''">
          <Cta
            v-if="data.cta && data.cta !== ''"
            :data="data.cta"
          />
        </div>
      </div>
      <div
        v-if="data.rich_text !== ''"
        class="block__content"
      >
        <RichText
          v-if="data.rich_text !== ''"
          :data="{ value: data.rich_text }"
        />
        <Cta
          v-if="data.cta && data.cta !== ''"
          :data="data.cta"
        />
      </div>
    </Grid>
  </Spacer>
</template>

<script>
import Title from '@/components/typo/title';
import Label from '@/components/typo/label';
import RichText from '@/components/typo/richtext';
import Cta from '@/components/typo/cta';

export default {
  name: 'Content',
  components: {
    Title,
    Label,
    RichText,
    Cta,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
