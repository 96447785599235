export default {
  data() {
    return {
      post: null,
    };
  },
  props: {
    slug: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
  },
  created() {
    this.setData(this.$route);
  },
  methods: {
    setData(to) {
      const request = {
        type: null,
        slug: null,
      };
      request.type = this.type || to.meta.type || 'pages';

      if (this.slug) {
        request.slug = this.slug;
      } else if (to.params.slug) {
        request.slug = to.params.slug;
      } else if (to.meta.slug) {
        request.slug = to.meta.slug;
      }

      this.post = this.$store.state.wp[request.type][request.slug];
      if (request.type === 'pages') {
        this.$store.commit('SET_CURRENT_POST', this.post);
      }
    },
  },
};
