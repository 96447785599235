<template>
  <div
    v-if="isOverlay"
    class="overlay-main"
  >
    <transition
      name="fade"
      appear
      :css="true"
      mode="out-in"
    >
      <router-view
        :key="$route.path"
        name="overlay"
      />
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'RouterOverlay',
  computed: {
    ...mapGetters(['isOverlay']),
  },
  watch: {
    isOverlay: {
      immediate: true,
      handler(val) {
        const fn = val ? 'add' : 'remove';
        document.body.classList[fn]('overscroll-behavior');
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.overlay-main {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
}
</style>
