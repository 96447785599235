import medusa from '@/assets/js/observer';

export default {
  data() {
    return {
      request: null,
      type: null,
      items: [],
      placeholder: '',
      offset: 0,
      slug: this.$store.state.route.meta.slug ? this.$store.state.route.meta.slug : this.$store.state.route.params.slug,
    };
  },
  mounted() {
    this.setRequest();
    this.loadItems().then(() => {
      if (this.data.infinite_scroll) {
        this.$nextTick(this.setInfiniteScroll);
      } else {
        this.$root.$children[0].lazyObserve(this.$el);
      }
    });
  },
  beforeDestroy() {
    if (medusa.ref && medusa.ref.idList.includes('infinite-scroll')) {
      medusa.ref.removeTarget('infinite-scroll');
    }
  },
  methods: {
    async loadItems() {
      const items = await this.$store.dispatch('getItems', this.request);
      if (items) {
        this.items = [...this.items, ...items];
      }

      return items;
    },
    setRequest() {
      let postType = null;
      const category = {};
      let include = [];

      this.type = this.data.archive_type;

      if (this.type === 'archive') {
        const { archive, archive_categories, tags } = this.data;
        postType = archive === 'post' || archive === 'page' ? `${archive}s` : archive;
        if (archive_categories) {
          category[archive_categories[0].taxonomy] = archive_categories[0].term_id;
        }
        if (tags) {
          category.tags = tags;
        }
      } else if (this.type === 'posts') {
        postType = `${this.data[this.type][0].post_type}s`;
        include = this.data[this.type].map((item) => item.ID);
      } else if (this.type === 'taxonomy') {
        postType = 'categories';
        include = this.data[postType].map((item) => item.term_id);
      }

      this.request = {
        type: postType,
        params: {
          ...category,
          search: this.data.search ? this.data.search : null,
          include: include.length > 0 ? include : null,
          per_page: this.data.infinite_scroll ? parseInt(this.data.posts_per_page, 10) || 12 : parseInt(this.data.max_posts, 10) || 100,
          offset: this.offset,
        },
      };
    },
    setInfiniteScroll() {
      medusa.ref.addTarget({
        id: 'infinite-scroll',
        threshold: 0.1,
        nodes: this.$el.querySelector('.infinite'),
        mode: 'default',
        callback: this.setOffset,
        autoremove: false,
      });
    },
    setOffset(entry) {
      if (entry.isIntersecting) {
        this.requestOffset();
      }
    },
    requestOffset() {
      this.offset += parseInt(this.data.posts_per_page, 10) || 12;
      this.setRequest();
      this.loadItems().then((newItems) => {
        this.$root.$children[0].lazyObserve(this.$el);

        if (newItems.length === 0) {
          medusa.ref.removeTarget('infinite-scroll');
        } else if ((window.pageYOffset + window.innerHeight) === document.body.offsetHeight) {
          this.requestOffset();
        }
      });
    },
  },
};
