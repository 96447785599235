<template>
  <main class="single">
    <div class="single__header">
      <Grid :col="{ default: 4, m: 2 }">
        <div
          v-if="post.acf.single_year || post.acf.single_location"
          class="single__meta"
        >
          {{ post.acf.single_year }} <span v-if="post.acf.single_location">-</span> {{ post.acf.single_location }}
        </div>
        <Title
          class="single__title"
          :data="{ value: post.title.rendered }"
        />
        <div
          class="single__close"
        >
          <div
            class="close__trigger"
            @click="goBack"
          />
        </div>
      </Grid>
    </div>
    <div class="single__gallery">
      <Gallery
        v-if="post.acf.single_gallery"
        :data="post.acf.single_gallery"
      />
    </div>
    <div class="single__description">
      <RichText
        v-if="post.content.rendered !== ''"
        :data="{ value: post.content.rendered }"
      />
    </div>
  </main>
</template>

<script>
import Gallery from '@/components/media/gallery';
import Title from '@/components/typo/title';
import RichText from '@/components/typo/richtext';

import data from '@/mixins/data';

export default {
  name: 'Single',
  components: {
    Gallery,
    Title,
    RichText,
  },
  mixins: [data],
  methods: {
    goBack() {
      if (this.$store.state.route.from && this.$store.state.route.from.name !== null) {
        this.$router.push(this.$store.state.route.from.path);
      } else {
        this.$router.push('/lavorazioni');
      }
    },
  },
};
</script>

<style lang="scss">
.single {
  background-color: $secondary-color;
}
.single__header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 24px;
  text-align: center;
  .grid {
    align-items: flex-start;
    @include mq(m) {
      align-items: center;
    }
  }
}
.single__title {
  @include mq(m) {
    grid-column-start: 3;
    grid-column-end: span 2;
  }
}
.single__close {
  @include mq(m) {
    grid-column-start: 6;
  }
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  .close__trigger {
    height: 42px;
    width: 42px;
    background-image: url('~@/assets/img/close.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    pointer-events: auto;
  }
}
.single__close,
.single__meta {
  font-weight: $bold-unit;
  text-transform: uppercase;
  @extend %typo;

  @include mq(m) {
    line-height: 60px;
  }
}
.single__meta {
  text-align: left;
}
.single__gallery {
  // position: absolute;
  position: relative;
  // padding-top: 108px;
  // bottom: 108px;
  left: 0;
  right: 0;
  height: calc(100vh - 172px);
  @include mq(m) {
    height: calc(100vh - 258px);
  }
}
.single__description {
  // position: absolute;
  // bottom: 0;
  position: relative;
  padding: 24px;
  text-align: center;
}
</style>
