<template>
  <footer
    class="page-footer"
  >
    <!-- Default 0 opacity -->
    <AppHeader />
    <Flex
      v-if="options && options.description"
      class="page-footer__description"
    >
      <div>
        <div class="footer__label">
          Contattaci
        </div>
        <div v-html="options.description" />
      </div>
    </Flex>
    <div class="page-footer__wrapper">
      <Grid
        v-if="options && options.list"
        :col="{
          default : 12,
          'xxs' : 6,
          's': 3
        }"
        class="page-footer__list"
      >
        <div
          v-for="(item, index) in options.list"
          :key="index"
        >
          <div
            class="footer__label"
            v-html="item.label"
          />
          <div
            class="list__content"
            v-html="item.content"
          />
        </div>
      </Grid>
      <div class="page-footer__credits">
        <Flex
          v-for="(item, index) in options.credits"
          :key="index"
          align="center"
        >
          <div v-html="item.label" />
          <div v-html="item.content" />
        </Flex>
      </div>
    </div>

    <!-- <Grid
      col="4"
      class="page-footer__wrapper"
    >
      <div
        v-if="options && options.social"
        class="page-footer__social"
      >
        <div
          v-for="(item, index) in options.social"
          :key="index"
        >
          <a
            :href="item.url"
            v-html="item.label"
          />
        </div>
      </div>
      <div
        v-if="options && options.credits"
        class="page-footer__credits"
      >

      </div>
    </Grid> -->
  </footer>
</template>

<script>
import AppHeader from '@/components/ui/header';

export default {
  name: 'Footer',
  components: {
    AppHeader,
  },
  computed: {
    options() {
      const { footer } = this.$store.state.wp.options;
      return footer;
    },
  },
};
</script>

<style lang="scss" scoped>
.page-footer {
  background-color: $green;
  box-shadow: 0 24px 72px -18px rgba(0,0,0,.2);
  @include mq(s){
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    min-height: 100vh;
    text-align: center;
  }
}
.page-footer__description {
  @extend %typo--xxl;
  padding: 24px;
  padding-bottom: 48px;
  @include mq(s){
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
  @include mq(m){
    padding-left: 36px;
    padding-right: 36px;
  }
  .footer__label {
    text-transform: uppercase;
    font-weight: 800;
    margin-bottom: 12px;
    @extend %typo--s;
  }
}
.page-footer__wrapper {
  @extend %typo;
  padding: 24px 24px 12px;
  @include mq(s){
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
  @include mq(m){
    padding: 36px 36px 12px;
  }
}
.footer__label {
  font-weight: $bold-unit;
  @extend %typo;
}
.page-footer__credits {
  padding-top: 12px;
  border-top: 1px solid rgba(0,0,0,0.1);
  @extend %typo--xs;
  margin-top: $unit*2;
  @include mq(xl){
    margin-top: $unit*4;
  }
}
.template--contact {
  .page-footer {
    padding-top: 120px;
    @include mq(s){
      padding-top: 0;
    }
  }
}
</style>
