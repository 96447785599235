var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cta"},[(_vm.data.fn)?[_c('button',{on:{"click":_vm.data.fn}},[_c('Label',{attrs:{"data":{
          value: _vm.data.title,
          size: _vm.data.size || 's',
          tag: 'div'
        }}}),(_vm.data.toggle)?_vm._t(_vm.data.toggle):_vm._e()],2)]:(_vm.data.external)?[_c('a',{attrs:{"href":_vm.data.url,"target":_vm.data.target}},[_c('Label',{attrs:{"data":{
          value: _vm.data.title,
          size: _vm.data.size || 's',
          tag: 'div'
        }}}),(_vm.data.toggle)?_vm._t(_vm.data.toggle):_vm._e()],2)]:[_c('router-link',{attrs:{"to":_vm.data.url}},[_c('Label',{attrs:{"data":{
          value: _vm.data.title,
          size: _vm.data.size || 's',
          tag: 'div'
        }}}),(_vm.data.toggle)?_vm._t(_vm.data.toggle):_vm._e()],2)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }