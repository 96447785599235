<template>
  <header
    class="page-header"
  >
    <Flex
      class="header__branding"
    >
      <router-link
        to="/"
        class="header__logo"
      />
      <div
        class="header__trigger"
        @click="toggleNavigation"
      />
    </Flex>
    <AppMenu />
  </header>
</template>

<script>
import AppMenu from '@/components/ui/menu';

export default {
  name: 'Header',
  components: {
    AppMenu,
  },
  mounted() {
    document.body.classList.remove('blocked');
  },
  methods: {
    toggleNavigation() {
      document.querySelector('.page-header').classList.toggle('open');
      document.body.classList.toggle('blocked');
    },
  },
};
</script>

<style lang="scss">
.page-header {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 10;
  pointer-events: none;
  background: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0));
  @include mq(s){
    background: linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0));
  }
  &.open {
    .header__navigation {
      clip-path: inset(0 0 0 0);
    }
    .header__trigger {
      background-image: url('~@/assets/img/close_white.svg');
    }
  }
}
.header__branding {
  padding: 24px;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 12;
  @include mq(s){
    justify-content: center;
    z-index: 9;
  }
  @include mq(m){
    padding: 24px 36px;
  }
}
.header__logo {
  pointer-events: auto;
  height: 42px;
  width: 200px;
  background-image: url('~@/assets/img/logo_horizontal_white.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  @include mq(s){
    height: 60px;
    background-image: url('~@/assets/img/logo_white.svg');
  }
}
.header__trigger {
  height: 42px;
  width: 42px;
  background-image: url('~@/assets/img/menu_white.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  pointer-events: auto;
  @include mq(s){
    display: none;
  }
}
.page-footer {
  .page-header {
    display: none;
    background: transparent;
    pointer-events: auto;
    z-index: 12;
    .navigation__item {
      color: $black;
      pointer-events: auto;
    }
    .header__logo {
      background-image: url('~@/assets/img/logo.svg');
      pointer-events: auto;
    }
    @include mq(s){
      display: block;
    }
  }
}
.template--white,
.template--contact {
  .page-header {
    background: transparent;
    .navigation__item {
      color: $black;
    }
    .header__logo {
      background-image: url('~@/assets/img/logo_horizontal.svg');
      @include mq(s){
        background-image: url('~@/assets/img/logo.svg');
      }
    }
    .header__trigger {
      background-image: url('~@/assets/img/menu.svg');
    }
    &.open {
      .navigation__item {
        color: $white;
      }
      .header__logo {
        background-image: url('~@/assets/img/logo_horizontal_white.svg');
      }
      .header__trigger {
        background-image: url('~@/assets/img/close_white.svg');
      }
    }
  }
}
.template--contact {
  .page-header {
    @include mq(s){
      display: none;
    }
  }
  .page-footer {
    .page-header {
      @include mq(s){
        display: block;
      }
    }
  }
}
</style>
