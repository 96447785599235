import Vue from 'vue';
import { sync } from 'vuex-router-sync';

import App from './App.vue';
import router from './router';
import store from './store';

import '@/assets/js/eventBus';
import '@/assets/js/layout';

sync(store, router);
Vue.config.productionTip = false;

const app = new Vue({
  router,
  store,
  render: (h) => h(App),
});

router.onReady(() => app.$mount('#app'));
