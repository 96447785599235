export default {
  menu: (state) => (name) => state.wp.menus[name],
  loading: (state) => state.wp.site.loading,
  request: (state) => ({ type, params }) => state.wp[type].requests.find((req) => {
    let res = null;
    if (Object.keys(req.params).length === Object.keys(params).length) {
      res = Object.keys(req.params).every((key) => JSON.stringify(req.params[key]) === JSON.stringify(params[key]));
    }
    return res;
  }),
  totalPages: (state, getters) => ({ type, params }) => {
    const request = getters.request({ type, params });
    return request ? request.totalPages : 0;
  },
  requestedItems: (state, getters) => ({ type, params }) => {
    const request = getters.request({ type, params });
    return request ? request.data.map((id) => state.wp[type][id]) : [];
  },
  singleBySlug: (state) => ({ type, slug }) => {
    let single = null;
    // eslint-disable-next-line no-restricted-syntax
    for (const id in state.wp[type]) {
      if (decodeURI(state.wp[type][id].slug) === slug) {
        single = state.wp[type][id];
      }
    }
    return single;
  },
  postTypeBySlug: (state) => ({ slug }) => {
    let post = null;
    // eslint-disable-next-line no-restricted-syntax
    for (const key in state.wp.postTypes) {
      // eslint-disable-next-line no-prototype-builtins
      if (state.wp.postTypes.hasOwnProperty(key)) {
        if (key === slug) {
          post = state.wp.postTypes[key];
        }
      }
    }
    return post;
  },
  singleById: (state) => ({ type, id }) => state.wp[type][id],
  isTouch: (state) => state.touch,
  breakpoints: (state) => state.breakpoints,
  currentTemplate: (state) => (state.currentPost && state.currentPost.acf.page_template ? state.currentPost.acf.page_template : null),
  currentPost: (state) => state.currentPost,
  isOverlay: (state) => state.isOverlay,
};
