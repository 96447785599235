<template>
  <div
    id="app"
    :class="[
      isOverlay ? 'app--is-overlay' : false,
      loading ? 'app--loading' : false,
    ]"
  >
    <transition
      :css="false"
      appear
      mode="out-in"
      @enter="enter"
      @leave="leave"
    >
      <div
        :key="filteredPath"
        :class="[currentTemplate ? `template--${currentTemplate}` : false]"
      >
        <AppHeader ref="header" />
        <div
          class="main--primary"
          :style="isOverlay ? { transform: `translate3d(0, ${offset * -1}px, 0)`} : false "
        >
          <router-view :key="filteredPath" />
        </div>

        <RouterOverlay />
        <AppFooter ref="footer" />
      </div>
    </transition>
  </div>
</template>

<script>
import debounce from 'lodash.debounce';
import { mapGetters } from 'vuex';
import medusa from '@/assets/js/observer';
import { isTouchDevice, is } from '@/assets/js/utils';
import lazyload from '@/mixins/lazyload';
import gsap from 'gsap';

import AppHeader from '@/components/ui/header';
import AppFooter from '@/components/ui/footer';
import RouterOverlay from '@/components/routerOverlay';

export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter,
    RouterOverlay,
  },
  mixins: [lazyload],
  data() {
    return {
      offset: 0,
    };
  },
  computed: {
    ...mapGetters([
      'currentTemplate',
      'currentPost',
      'isOverlay',
      'loading',
    ]),
    filteredPath() {
      let prev = this.$store.state.route.from && this.$store.state.route.from.name ? this.$store.state.route.from.path : null;
      if (prev && prev.length > 1 && prev.endsWith('/')) prev = prev.slice(0, -1);

      const prevPath = prev || '/lavorazioni';
      let { path } = this.$route;
      if (path.length > 1 && path.endsWith('/')) path = path.slice(0, -1);
      const route = this.$route.name !== 'Single' ? path : prevPath;
      return route;
    },
  },
  watch: {
    isOverlay(val) {
      if (val) {
        this.offset = window.pageYOffset;
      } else {
        const prevOffset = this.offset;
        this.$nextTick(() => {
          window.scroll(0, prevOffset);
        });
        this.offset = 0;
      }
    },
  },
  created() {
    medusa.init();
  },
  mounted() {
    // Look at the state ;)

    // Redirect in local development
    if (window.location.port === '8888') {
      window.location.href = window.location.href.replace('8888', '3000');
    }

    window.addEventListener('resize', debounce(() => {
      this.$bus.$emit('windowResized');
    }, 400));

    this.$bus.$on('windowResized', this.resize);
    this.resize();

    if (this.$refs.footer.$el && document.body.classList.contains('app-loaded')) {
      gsap.set(this.$refs.footer.$el, {
        autoAlpha: 0,
      });
    }
    if (this.$refs.header.$el && document.body.classList.contains('app-loaded')) {
      gsap.set(this.$refs.header.$el, {
        autoAlpha: 0,
      });
    }
    // if (document.querySelector('.gds-cookie')) {
    //   gsap.set(document.querySelector('.gds-cookie'), {
    //     autoAlpha: 0,
    //   });
    // }
  },
  methods: {
    resize() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);

      const breakpoints = window.getComputedStyle(document.documentElement).getPropertyValue('--breakpoints').replace(/[ '"]+/g, '');
      this.$store.commit('SET_BREAKPOINTS', breakpoints);
      this.$store.commit('SET_TOUCH', isTouchDevice());
      this.$store.commit('SET_MOBILE', is('m'));
    },
    enter(el, done) {
      gsap.set(el, {
        y: window.innerHeight,
      });
      gsap.to(el, {
        y: 0,
        duration: 0.6,
        ease: 'power3.inOut',
        clearProps: 'all',
        onComplete: () => {
          if (this.$refs.footer.$el && document.body.classList.contains('app-loaded')) {
            gsap.set(this.$refs.footer.$el, {
              clearProps: 'all',
            });
          }
          if (this.$refs.header.$el && document.body.classList.contains('app-loaded')) {
            gsap.set(this.$refs.header.$el, {
              clearProps: 'all',
            });
          }
          // if (document.querySelector('.gds-cookie')) {
          //   setTimeout(() => {
          //     gsap.set(document.querySelector('.gds-cookie'), {
          //       autoAlpha: 1,
          //     });
          //   }, 1000);
          // }
          done();
        },
      });
    },
    leave(el, done) {
      gsap.to(el, {
        autoAlpha: 0,
        duration: 0.9,
        ease: 'power3.inOut',
        onComplete: () => {
          done();
          window.scroll(0, 0);
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/style.scss';

.fade-enter-active {
  transition: transform .6s cubic-bezier(0.77, 0, 0.175, 1);
}
.fade-enter {
  transform: translateY(100%)
}
.fade-leave-active {
  transition: opacity .9s cubic-bezier(0.77, 0, 0.175, 1);
}
.fade-leave-to {
  opacity: 0;
}

.app--loading {
  cursor: wait !important;
}

.app--is-overlay {
  .main--primary {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: -1;

    @include mq(m) {
      z-index: 0;
    }
  }
}

#app {
  opacity: 0;
  transition: opacity .3s ease;
  cursor: wait;
  .app-loaded & {
    opacity: 1;
    cursor: auto;
  }
}

</style>
