<template>
  <nav class="header__navigation">
    <Grid
      :col="{
        default: 12,
        s: 2,
      }"
      class="navigation__wrapper"
    >
      <div
        v-for="item in navigation"
        :key="item.id"
        :class="item.css + 'navigation__item'"
      >
        <router-link :to="item.url">
          {{ item.content }}
        </router-link>
      </div>
    </Grid>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Menu',
  computed: {
    ...mapGetters(['menu']),
    navigation() {
      const nav = this.menu('navigation');
      return nav ? nav.items : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.header__navigation {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 11;
  pointer-events: none;
  padding: 24px;
  clip-path: inset(0 0 100% 0);
  // transform: translateY(-200%);
  background-color: $black;
  height: 100vh;
  display: flex;
  align-items: flex-end;
  padding-bottom: 30px;
  transition: clip-path 0.5s cubic-bezier(0.22, 1, 0.36, 1);
  @include mq(s){
    clip-path: inset(0 0 0 0);
    background-color: transparent;
    height: auto;
    display: block;
    padding-bottom: 0;
  }
  @include mq(m){
    padding: 24px 36px;
  }
}
.navigation__item {
  display: flex;
  align-items: center;
  text-align: center;
  color: $white;
  margin-top: -$unit;
  @include mq(s){
    height: 60px;
    padding: 18px 12px;
    margin-top: 0;
    justify-content: center;
    &:nth-child(3) {
      grid-column-start: 5;
    }
  }
  a {
    font-size: $unit*3;
    line-height: $unit*3;
    pointer-events: auto;
    @include mq(s){
      font-weight: $bold-unit;
      font-size: 15px;
      line-height: $unit*1.5;
    }
    @include mq(l){
      font-size: $unit*1.5;
      line-height: $unit*2;
    }
    &.router-link-exact-active {
      @include mq(s){
        border-bottom: 1px solid;
      }
    }
  }
}
</style>
